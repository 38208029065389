import React from "react";
import i18n from "../../i18n";
import { Link, NavLink } from "react-router-dom";
import { getLoggedUser } from "../../state/user";
import { getCategories } from "../../models/categories";

const user = getLoggedUser();
const roles = {
  A: i18n.t("admin"),
  C: i18n.t("client"),
  CE: i18n.t("ceo"),
  GM: i18n.t("gm"),
  F: i18n.t("finance"),
  HSE: i18n.t("hse"),
  M: i18n.t("manager"),
  PM: i18n.t("PM"),
  U: i18n.t("bidder"),
};

const changeLanguage = (ln) => {
  i18n.changeLanguage(ln);
  window.location.reload();
};

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("mzad_user_info");
  window.location.href = "/";
}

export class SidebarHomeMob extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
    };
    this.offcanvasRef = React.createRef();
  }

  componentDidMount() {
    this.loadAllCategories();
  }
  async loadAllCategories() {
    const response = await getCategories();
    let categories = response.data;
    this.setState({ categories });
  }

  hideOffcanvas() {
    let offcanvas = document.querySelector(".offcanvas-end");
    offcanvas.classList.add("hiding");
    offcanvas.classList.remove("showing");
    offcanvas.classList.remove("show");
    let backdrop = document.querySelector(".offcanvas-backdrop");
    if (backdrop) {
      backdrop.remove();
    }
  }

  showOffcanvas() {
    let offcanvas = document.querySelector(".offcanvas-end");
    offcanvas.classList.remove("hiding");
    offcanvas.classList.add("showing");
    offcanvas.classList.add("show");
    let backdrop = document.querySelector(".offcanvas-backdrop");
    if (!backdrop) {
      offcanvas.insertAdjacentHTML(
        "afterend",
        '<div class="offcanvas-backdrop fade show"></div>'
      );
    }
  }

  render() {
    let categories = this.state.categories;
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let { wallet_amount, is_loading, is_logged_in } = this.props;

    return (
      <React.Fragment>
        {/* SIDEBAR */}
        <button
          className="btn btn-primary sidebar-btn"
          type="button"
          onClick={this.showOffcanvas}
        >
          <img
            src={
              process.env.PUBLIC_URL + "/assets/images/burger-menu-black.png"
            }
            alt="burger-menu"
            style={{ width: "20px" }}
          />
        </button>

        <div
          className="offcanvas offcanvas-end"
          data-bs-scroll="true"
          tabindex="-1"
          id="offcanvasWithBothOptions"
          aria-labelledby="offcanvasWithBothOptionsLabel"
        >
          <div className="offcanvas-top-section">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={this.hideOffcanvas}
            ></button>
            <span className="offcanvas-user">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/offscreen-user.png"
                }
                alt="user"
              />
            </span>
            <div className="offcanvas-welcome">
              <span className="welcome-guest">
                {i18n.t("welcome")}{" "}
                {user && user.name ? user.name : i18n.t("guest")}
              </span>
              {is_logged_in && (
                <span className="offcanvas-welcome-edit">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/offcanvas-pencil.png"
                    }
                    alt="edit"
                  />
                  <Link to="/profile" onClick={this.hideOffcanvas}>
                    {i18n.t("edit")}
                  </Link>
                </span>
              )}
            </div>

            {is_logged_in && (
              <span className="offcanvas-role">
                {i18n.t("role")}:{" "}
                {user && user.role ? roles[user.role] : "Guest"}
              </span>
            )}

            {is_logged_in && (
              <Link
                className="offcanvas-wallet"
                to="/wallet"
                onClick={this.hideOffcanvas}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/wallet-white.png"
                  }
                  alt="wallet"
                />
                <span>
                  {is_loading && <span>..</span>}
                  {!is_loading && (
                    <span>
                      {i18n.t("wallet")} : {i18n.t("omr")} {wallet_amount}
                    </span>
                  )}
                </span>
              </Link>
            )}
          </div>
          <div className="offcanvas-body">
            {!is_logged_in && (
              <NavLink
                exact="true"
                to="/login"
                style={{ borderRadius: "0" }}
                onClick={this.hideOffcanvas}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/Vector_login.png"
                  }
                  width="10"
                  alt=""
                  style={{ filter: "invert(1)" }}
                />
                &nbsp;
                {i18n.t("login")}
              </NavLink>
            )}
            {!is_logged_in && (
              <NavLink
                className=""
                exact="true"
                to="/comp-registration"
                onClick={this.hideOffcanvas}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/register-icon.png"
                  }
                  style={{ filter: "invert(1)" }}
                  alt="register"
                />
                &nbsp;
                <span>{i18n.t("registration")}</span>
              </NavLink>
            )}

            <NavLink to="/" onClick={this.hideOffcanvas}>
              <i className="fa fa-home" />
              <span className="mx-2">{i18n.t("home")}</span>
            </NavLink>
            <Link to="/about" onClick={this.hideOffcanvas}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/offcanvas-aboutus.png"
                }
                alt="About-us"
                width="15"
              />
              <span className="mx-2">{i18n.t("whoweare")}</span>
            </Link>
            <Link to="/auc-calendar" onClick={this.hideOffcanvas}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/offcanvas-calendar.png"
                }
                alt="calendar"
                width="15"
              />
              <span className="mx-2">{i18n.t("auction_calendar")}</span>
            </Link>
            <Link to="/notifications" onClick={this.hideOffcanvas}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/offcanvas_notification.png"
                }
                alt="notification"
                width="15"
              />
              <span className="mx-2">{i18n.t("notifications")}</span>
            </Link>

            <Link to="/contact" className="" onClick={this.hideOffcanvas}>
              <i className="fa fa-envelope-o" />
              <span className="mx-2">{i18n.t("contactus")}</span>
            </Link>

            <a
              href={false}
              onClick={() => {
                this.props.callbackForSupport(true);
                this.hideOffcanvas();
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/images/offcanvas-help.png"
                }
                alt="help"
                width="15"
              />
              <span className="mx-2">{i18n.t("help")}</span>
            </a>

            <div className="offcanvas-mid-section">
              {is_logged_in && (
                <Link
                  to="/dashboard"
                  className="text-danger"
                  onClick={this.hideOffcanvas}
                >
                  <i className="fa fa-dashboard" />
                  <span className="mx-2">{i18n.t("dashboard")}</span>
                </Link>
              )}
              <a
                href={false}
                className="menu-category text-dark"
                onClick={() => {
                  this.setState({
                    is_cat_active: !this.state.is_cat_active,
                  });
                }}
              >
                <span className="px-2">
                  <i className="fa fa-list-alt" />
                  &nbsp;
                  {i18n.t("category")}
                </span>
                <span>
                  <i
                    className={
                      this.state.is_cat_active
                        ? "fa fa-angle-up"
                        : "fa fa-angle-down"
                    }
                  />
                </span>
              </a>
              <div
                className={
                  this.state.is_cat_active
                    ? "offcanvas-auctions active"
                    : "offcanvas-auctions"
                }
              >
                {categories.map((item, i) => {
                  return (
                    <NavLink
                      to={
                        item.total_auctions > 0
                          ? "/auctions?category=" + item.id
                          : false
                      }
                      exact={true}
                      onClick={this.hideOffcanvas}
                    >
                      <img
                        src={item.file_category_image}
                        alt="metal"
                        width="25"
                        style={{ marginRight: "10px" }}
                      />
                      <span className="mx-2">
                        {current_lng === "ar"
                          ? item.category_name_ar
                          : item.category_name}
                      </span>

                      <span class="badge badge-info border text-info">
                        {item.total_auctions}
                      </span>
                    </NavLink>
                  );
                })}
              </div>

              <a
                href={false}
                className="menu-category text-dark"
                onClick={() => {
                  this.setState({
                    is_auc_menu_active: !this.state.is_auc_menu_active,
                  });
                }}
              >
                <span className="px-2">
                  <i className="fa fa-gavel" />
                  &nbsp;
                  {i18n.t("auctions")}
                </span>
                <span>
                  <i
                    className={
                      this.state.is_auc_menu_active
                        ? "fa fa-angle-up"
                        : "fa fa-angle-down"
                    }
                  />
                </span>
              </a>
              <div
                className={
                  this.state.is_auc_menu_active
                    ? "offcanvas-auc-menu active"
                    : "offcanvas-auc-menu"
                }
              >
                <Link
                  to="/auctions?sec=main&type=active"
                  className="text-dark"
                  onClick={this.hideOffcanvas}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/active_auction.png"
                    }
                    alt=""
                    width="15"
                  />
                  &nbsp;
                  {i18n.t("active_auctions")}
                </Link>
                <Link
                  to="/auctions?sec=main&type=upcoming"
                  className="text-dark"
                  onClick={this.hideOffcanvas}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/upcoming_auction.png"
                    }
                    alt=""
                    width="15"
                  />
                  &nbsp;
                  {i18n.t("upcoming_auctions")}
                </Link>
                <Link
                  to="/auctions?sec=main&type=previous"
                  className="text-dark"
                  onClick={this.hideOffcanvas}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/expired_auction.png"
                    }
                    alt=""
                    width="15"
                  />
                  &nbsp;
                  {i18n.t("previous_auctions")}
                </Link>
                <Link
                  to="/auctions?sec=main&type=featured"
                  className="text-dark"
                  onClick={this.hideOffcanvas}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/featured_auction.png"
                    }
                    alt=""
                    width="15"
                  />
                  &nbsp;
                  {i18n.t("featured_auctions")}
                </Link>
                <Link
                  to="/auctions?sec=main&type=direct"
                  className="text-dark"
                  onClick={this.hideOffcanvas}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/direct_auctions.png"
                    }
                    alt=""
                    width="15"
                  />
                  &nbsp;
                  {i18n.t("direct_sale")}
                </Link>
              </div>
            </div>
            <div className="offcanvas-bottom-section">
              {is_logged_in && (
                <NavLink exact="true" to={true} onClick={logout}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/Vector_login.png"
                    }
                    width="10"
                    alt=""
                    style={{ filter: "invert(1)" }}
                  />
                  &nbsp;
                  {i18n.t("logout")}
                </NavLink>
              )}
              <div className="d-flex justify-content-between w-100 px-4 mt-1">
                <span>
                  <i className="fa fa-globe"></i>&nbsp;
                  {i18n.t("language")}
                </span>
                <div className="d-flex">
                  <span
                    className={
                      current_lng === "ar"
                        ? "d-flex border rounded bg-info mx-1"
                        : "d-flex border rounded mx-1"
                    }
                  >
                    <a
                      href={true}
                      onClick={(e) => changeLanguage("ar")}
                      className="px-3"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/flags/ar.svg"
                        }
                        width="20"
                        alt=""
                      />
                    </a>
                  </span>
                  <span
                    className={
                      current_lng === "en"
                        ? "d-flex border rounded bg-info mx-1"
                        : "d-flex border rounded mx-1"
                    }
                  >
                    <a
                      href={true}
                      onClick={(e) => changeLanguage("en")}
                      className="px-3"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/flags/en.svg"
                        }
                        height="20"
                        alt=""
                      />
                    </a>
                  </span>
                </div>
              </div>

              {/* {current_lng === "en" && (
                  <a href={true} onClick={(e) => changeLanguage("ar")}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/flags/ar.svg"
                      }
                      height="20"
                      alt=""
                    />{" "}
                    عربي{" "}
                  </a>
                )}
                {current_lng === "ar" && (
                  <a href={true} onClick={(e) => changeLanguage("en")}>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/flags/en.svg"
                      }
                      height="20"
                      alt=""
                    />{" "}
                    English{" "}
                  </a>
                )} */}
            </div>
          </div>
        </div>
        {/* SIDEBAR END */}
      </React.Fragment>
    );
  }
}
