import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { createUser, deleteUser } from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Modal, Spinner } from "react-bootstrap";
import {
  getAllGatePasses,
  getGatePass,
  updateGatePass,
} from "../../models/gatePass";
import { getLoggedUser } from "../../state/user";
import { apiUrl } from "../../constants/global";
import {
  createGatePassMember,
  deleteGatePassMember,
  getAllGatePassMembers,
} from "../../models/gatePassMembers";
import { getAllAuctionsByGroup } from "../../models/auctions";
import { getAllGroups } from "../../models/groups";
import { HomeBanner } from "../home/homeBanner";
import FileInput from "../helpers/fileInput";

let lang = localStorage.getItem("I18N_LANGUAGE");
let user = getLoggedUser();
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e4f7ff",
      color: "#000000",
    },
  },
};

const statuses = {
  N: "New",
  NP: "Waiting for admin approval",
  P: "Waiting for PM approval",
  A: i18n.t("approved"),
  H: "Waiting for HSC Approval",
  I: "Issued",
};
const ptypes = {
  online: i18n.t("online"),
  offline: i18n.t("offline"),
  wallet: i18n.t("wallet"),
};

export class SiteVisit extends React.Component {
  constructor() {
    super();
    this.state = {
      continue_msg: "",
      is_hse: false,
      is_admin: false,
      is_pm: false,
      is_user: false,
      is_updating: false,
      open_popup: false,
      update_popup: false,
      add: {
        country_code: "+968",
      },
      data: {},
      errors: {},
      is_loading: false,
      users: {},
      selected_gid: null,
      members: [],
      member: {},
      hideSidebar: false,
      totalRows: 0,
      currentPage: 1,
      show_otp: false,
      row_otp: "",
      input: {
        page: 1,
        limit: 15,
      },
      groups: [],
      titles: [],
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          wrap: true,
          width: "50px",
        },
        {
          name: i18n.t("name"),
          selector: "bidder",
          sortable: true,
          cell: (row) => (row && row.bidder_info ? row.bidder_info.name : ""),
        },
        {
          name: i18n.t("username"),
          selector: "bidder",
          sortable: true,
          cell: (row) =>
            row && row.bidder_info ? row.bidder_info.username : "",
        },
        {
          name: i18n.t("title"),
          selector: "auction",
          sortable: true,
          cell: (row) => {
            let title = row && row.auction_info ? row.auction_info.title : "";
            let title_ar =
              row && row.auction_info && row.auction_info.title_ar
                ? row.auction_info.title_ar
                : "";
            return lang === "ar" ? title_ar : title;
          },
        },
        {
          name: i18n.t("payment_type"),
          selector: "payment_type",
          sortable: true,
          cell: (row) => ptypes[row.payment_type],
        },
        {
          name: i18n.t("verification_no"),
          selector: "otp",
          sortable: true,
          wrap: true,
          cell: (row) => {
            return row.otp ? (
              <button
                className="btn btn-outline-success btn-sm"
                onClick={() => {
                  this.setState({ row_otp: row.otp });
                  this.setState({ show_otp: true });
                }}
              >
                {i18n.t("view")}
              </button>
            ) : (
              "xxxxxx"
            );
          },
        },
        {
          name: i18n.t("schedule_time"),
          selector: "visit_time",
          sortable: true,
          wrap: true,
        },
        {
          name: i18n.t("status"),
          selector: "status",
          sortable: true,
          cell: (row) => statuses[row.status],
        },
        {
          name: i18n.t("receipt"),
          selector: "file_receipt",
          sortable: true,
          cell: (row) => {
            if (user.role === "A" && row.file_receipt) {
              return (
                <a href={row.file_receipt} target="_new">
                  {i18n.t("download")}
                </a>
              );
            }
          },
        },
        {
          name: i18n.t("action"),
          button: true,
          cell: (row) => {
            if (
              user.role === "A" &&
              row.status === "NP" &&
              row.payment_type === "offline"
            ) {
              return (
                <button
                  className="btn btn-primary btn-md"
                  onClick={(e) => {
                    this.setState({ is_admin: true, continue_msg: "" });
                    this.openPopup(row.id);
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }

            if (
              user.role === "PM" &&
              (row.status === "P" ||
                (row.status === "N" && row.payment_type === "offline"))
            ) {
              return (
                <button
                  className="btn btn-primary btn-md"
                  onClick={(e) => {
                    this.setState({ is_pm: true });
                    this.openPopup(row.id, true);
                  }}
                >
                  {i18n.t("schedule")}
                </button>
              );
            }
            if (user.role === "HSE" && row.status === "H") {
              return (
                <button
                  className="btn btn-info"
                  onClick={(e) => {
                    this.setState({
                      is_hse: true,
                      continue_msg:
                        i18n.t("verification_code") + " : " + row.otp,
                    });
                    this.openPopup(row.id);
                  }}
                >
                  {i18n.t("approve")}
                </button>
              );
            }
            if (row.status === "A" || row.status === "H") {
              return (
                <a
                  className="btn btn-info"
                  target="_new"
                  href={apiUrl + "gate_pass/pdf?gid=" + row.id}
                >
                  {i18n.t("download")}
                </a>
              );
            }
          },
        },
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
    this.triggerReload = this.triggerReload.bind(this);
    this.updateRow = this.updateRow.bind(this);

    this.createMember = this.createMember.bind(this);
    this.getAllMembers = this.getAllMembers.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    //this.updateMember = this.updateMember.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadGatePasses(qstring);
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.loadAuctionsByGrp(event.target.value);
    }
  }

  async loadAllGroups() {
    let q = "group_type=site_visit";
    const response = await getAllGroups(q);
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuctionsByGrp(org) {
    if (org) {
      let { input } = this.state;
      this.setState({ phone_loader: true });
      const response = await getAllAuctionsByGroup(org);
      if (response.data) {
        this.setState({ phone_loader: false });
        this.setState({ titles: response.data });
        let _data = response.data;
        input.auction = "";
        this.setState({ input });
        if (_data && _data.length) {
          let organization = _data[0].organization;
          input.organization = organization;
          this.setState({ input });
        }
      }
    }
  }

  handleMemberChange(event) {
    let { member } = this.state;
    member[event.target.name] = event.target.value;
    this.setState({ member });
  }

  onFileChange = (event) => {
    if (event.target.name === "file_civil_id") {
      this.setState({ file_civil_id: event.target.files[0] });
    }
  };

  handleSearch(event) {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.loadGatePasses(qstring);
  }

  async loadGatePasses(q) {
    this.setState({ is_loading: true });
    const response = await getAllGatePasses(q);
    let users = response.data;
    let meta = response.meta;
    if (response.success) {
      this.setState({ is_loading: false });
      this.setState({ users: users });
      this.setState({ totalRows: meta.total });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async openPopup(id, show_list) {
    const response = await getGatePass(id);
    let data = response.data;
    this.setState({ selected_gid: id });
    this.setState({ data, update_popup: true });
    if (show_list) {
      this.getAllMembers(id);
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadGatePasses(qstring);
  };

  async deleteRow(id) {
    var ans = window.confirm("Do you want to continue.?");
    if (ans) {
      const response = await deleteUser(id);
      if (response.success) {
        swal(i18n.t("success"), "User deleted", "success", {
          buttons: false,
          timer: 2000,
        })
          .then((response) => {
            window.location.reload();
          })
          .catch((response) => {
            swal("Failed", "Something went wrong", "error");
          });
      }
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      try {
        const response = await createUser(this.state.add);
        if (response.success) {
          swal(i18n.t("success"), "User created successfully!!", "success", {
            buttons: false,
            timer: 2000,
          })
            .then((value) => {
              this.resetForm();
              let { input } = this.state;
              let q = new URLSearchParams(input).toString();
              this.loadGatePasses(q);
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        } else {
          let error_message = response.message;
          let resObject = response.data;
          for (const key in resObject) {
            if (Object.hasOwnProperty.call(resObject, key)) {
              let element = resObject[key];
              error_message += "\n" + element;
            }
          }
          swal("Failed", error_message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async updateRow(payload) {
    this.setState({ is_updating: true });
    let { data } = this.state;
    let _payload = data;
    if (payload.status) {
      _payload.status = payload.status;
    }
    if (payload.visit_time) {
      _payload.visit_time = payload.visit_time;
    }
    const response = await updateGatePass(_payload, data.id);
    if (response && response.success) {
      swal(i18n.t("success"), "Data updated successfully!!", "success");
      this.resetForm();
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadGatePasses(qstring);
      this.setState({ is_updating: false, update_popup: false });
    } else {
      this.setState({ is_updating: false });
    }
  }

  resetForm() {
    let { input } = this.state;
    input["site_visit"] = "";
    this.setState({ input });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.add;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your Name.";
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = "Please enter a valid Username.";
    }
    if (!input["country_code"]) {
      isValid = false;
      errors["country_code"] = "Please enter phone country code.";
    }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your Phone Number.";
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter valid email address.";
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your Password.";
    }
    if (!input["role"]) {
      isValid = false;
      errors["role"] = "Please select a role.";
    }

    this.setState({
      errors: errors,
    });
    console.log(errors);
    return isValid;
  }

  triggerReload(opt) {
    if (opt) {
      let { input } = this.state;
      let qstring = new URLSearchParams(input).toString();
      this.loadGatePasses(qstring);
    }
  }

  async createMember(e) {
    e.preventDefault();
    this.setState({ is_mem_loading: true });
    let { member, selected_gid } = this.state;
    if (
      !member.name ||
      !member.civil_id ||
      !member.company_name ||
      !member.company_cr_no
    ) {
      swal(i18n.t("failed"), i18n.t("all_mandatory"), "error");
      return;
    }
    const formData = new FormData();
    formData.append("gate_pass", selected_gid);
    formData.append("name", member.name);
    formData.append("civil_id", member.civil_id);
    formData.append("company_name", member.company_name);
    formData.append("company_cr_no", member.company_cr_no);
    if (this.state.file_civil_id) {
      formData.append(
        "file_civil_id",
        this.state.file_civil_id,
        this.state.file_civil_id.name
      );
    }
    const response = await createGatePassMember(formData);
    if (response && response.success) {
      this.setState({ is_mem_loading: false });
      this.getAllMembers(selected_gid);
      swal(i18n.t("success", i18n.t("success_message")), "success");
    } else {
      swal(
        "Failed",
        "Some errors occured. please contact administrator",
        "error"
      );
      this.setState({ is_mem_loading: false });
      return;
    }
  }

  async getAllMembers() {
    let { selected_gid } = this.state;
    const response = await getAllGatePassMembers(selected_gid);
    if (response && response.success) {
      this.setState({ members: response.data });
    }
  }

  async deleteMember(id) {
    const response = await deleteGatePassMember(id);
    if (response && response.success) {
      swal(i18n.t("success"), "Data deleted successfully", "success");
      this.getAllMembers();
    }
  }

  render() {
    let { is_updating, users, members, groups, titles } = this.state;
    return (
      <React.Fragment>
        <HomeBanner />
        <section className="sptb mt-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header mb-0 d-flex justify-content-between">
                    <h3 className="card-title">{i18n.t("site_visit")}</h3>
                    <span
                      style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                      onClick={() => {
                        this.setState({
                          hideSidebar: !this.state.hideSidebar,
                        });
                      }}
                    >
                      <i
                        className={
                          !this.state.hideSidebar
                            ? "icon-size-fullscreen"
                            : "icon-size-actual"
                        }
                      ></i>
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      <form id="UpdateProfile" className="" autoComplete="off">
                        <div className="row">
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("groups")}
                              </label>
                              <select
                                className="form-select select fs14 text-black"
                                data-placeholder="Select Class"
                                name="group"
                                value={this.state.input.group}
                                onChange={this.handleChange}
                              >
                                <option value="">--{i18n.t("group")}--</option>
                                {groups.length > 0 &&
                                  groups.map((item) => {
                                    return (
                                      <option value={item.id} key={item.id}>
                                        {item.group_name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("auctions")}
                              </label>

                              <div className="input-group">
                                <select
                                  className="form-select select fs14 text-black"
                                  data-placeholder="Select Class"
                                  name="auction"
                                  value={this.state.input.auction}
                                  onChange={this.handleChange}
                                >
                                  <option value="">
                                    --{i18n.t("auctions")}--
                                  </option>
                                  {titles.length > 0 &&
                                    titles.map((item) => {
                                      return (
                                        <option value={item.id} key={item.id}>
                                          {item.title}
                                        </option>
                                      );
                                    })}
                                </select>

                                {this.state.phone_loader && (
                                  <span
                                    className="input-group-addon"
                                    style={{
                                      position: "absolute",
                                      marginLeft: "54%",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <i className="glyphicon glyphicon-refresh fa-spin"></i>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("status")}
                              </label>
                              <select
                                className="form-select select fs14 text-black"
                                data-placeholder="Select Class"
                                name="status"
                                value={this.state.input.status}
                                onChange={this.handleChange}
                              >
                                <option value="">--{i18n.t("status")}--</option>
                                {Object.keys(statuses).map((statusKey) => (
                                  <option value={statusKey} key={statusKey}>
                                    {statuses[statusKey]}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-2 mt-4">
                            <div className="form-group">
                              <button
                                type="button"
                                className="btn btn-warning rounded-5 px-3"
                                onClick={this.handleSearch}
                              >
                                {i18n.t("search")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>

                      {this.state.is_loading && (
                        <div className="text-center">
                          <Spinner animation="border" size="lg" />
                        </div>
                      )}

                      {users.length < 1 && (
                        <div className="alert alert-danger mt-3">
                          {i18n.t("no_result")}
                        </div>
                      )}

                      {!this.state.is_loading && users.length > 0 && (
                        <div className="table-wrapper">
                          <DataTable
                            title=""
                            fixedHeader
                            columns={this.state.columns}
                            data={users}
                            customStyles={customStyles}
                            highlightOnHover
                            pagination
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            paginationPerPage={15}
                            paginationDefaultPage={this.state.currentPage}
                            paginationRowsPerPageOptions={[15, 25, 50, 100]}
                            paginationComponentOptions={{
                              rowsPerPageText: i18n.t("users_per_page"),
                              rangeSeparatorText: i18n.t("out"),
                            }}
                            onChangePage={this.handlePageChange}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Modal
          size={this.state.is_pm ? "xl" : "lg"}
          show={this.state.update_popup}
          onHide={() => this.setState({ update_popup: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {this.state.is_pm
                ? i18n.t("schedule_time")
                : i18n.t("confirm_msg_continue")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.is_pm && (
              <div>
                <div className="row">
                  <div className="col-10">
                    <div class="form-group">
                      <label for="exampleInputEmail1">
                        {i18n.t("enter_date_time")}
                      </label>
                      <input
                        type="datetime-local"
                        class="form-control"
                        placeholder={i18n.t("enter_date_time")}
                        name="visit_time"
                        value={this.state.input.visit_time}
                        onChange={this.handleChange}
                      />
                      <small id="emailHelp" class="form-text text-muted">
                        {i18n.t("site_visit_time")}
                      </small>
                    </div>
                  </div>
                  <div className="col-2">
                    <div class="form-group">
                      <label for="">&nbsp;</label>
                      <button
                        type="button"
                        className="btn btn-danger btn-block"
                        onClick={() => {
                          let payload = {
                            status: "H",
                            visit_time: this.state.input.visit_time,
                          };
                          if (this.state.is_hse) {
                            payload = {
                              status: "A",
                            };
                          }
                          if (this.state.is_admin) {
                            payload = {
                              status: "P",
                            };
                          }
                          this.updateRow(payload);
                        }}
                      >
                        {is_updating ? "Saving.." : i18n.t("submit")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div class="form-group">
                    <div>
                      <h5>{i18n.t("accompanying_persons")}</h5>
                    </div>
                    <form>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>{i18n.t("name")}</th>
                            <th>{i18n.t("personal_card_no_1")}</th>
                            <th>{i18n.t("attach_id_no")}</th>
                            <th>{i18n.t("company")}</th>
                            <th>{i18n.t("cr_number")}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              <input
                                type="text"
                                placeholder={i18n.t("name")}
                                className="form-control"
                                name="name"
                                value={this.state.member.name}
                                onChange={this.handleMemberChange}
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                placeholder={i18n.t("personal_card_no")}
                                className="form-control"
                                name="civil_id"
                                value={this.state.member.civil_id}
                                onChange={this.handleMemberChange}
                              />
                            </th>
                            <th>
                              <FileInput
                                wrapperClass=" pad-adjust"
                                placeholder={i18n.t("attach_id_no")}
                                id="file_civil_id"
                                name="file_civil_id"
                                onChange={this.onFileChange}
                                className="form-control"
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                placeholder={i18n.t("company")}
                                className="form-control"
                                name="company_name"
                                value={this.state.member.company_name}
                                onChange={this.handleMemberChange}
                              />
                            </th>
                            <th>
                              <input
                                type="text"
                                placeholder={i18n.t("cr_number")}
                                className="form-control"
                                name="company_cr_no"
                                value={this.state.member.company_cr_no}
                                onChange={this.handleMemberChange}
                              />
                            </th>
                            <th>
                              <button
                                className="btn btn-dark"
                                onClick={(e) => {
                                  this.createMember(e);
                                }}
                              >
                                {i18n.t("add_new")}
                              </button>
                            </th>
                          </tr>
                          {members.map((item, i) => {
                            return (
                              <tr>
                                <td>
                                  {i + 1}
                                  {") "}
                                  {item.name}
                                </td>
                                <td>{item.civil_id}</td>
                                <td>
                                  <a
                                    className="btn btn-sm btn-outline-success"
                                    href={item.file_civil_id}
                                    target="_new"
                                  >
                                    {i18n.t("view")}
                                  </a>
                                </td>
                                <td>{item.company_name}</td>
                                <td>{item.company_cr_no}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.deleteMember(item.id);
                                    }}
                                  >
                                    {i18n.t("delete")}
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </form>
                  </div>
                </div>
              </div>
            )}

            {this.state.continue_msg && (
              <div className="text-center text-info mb-5 font-weight-bold">
                {this.state.continue_msg}
              </div>
            )}
            {!this.state.is_pm && <div>{i18n.t("confirm_msg_continue")}</div>}
          </Modal.Body>
          <Modal.Footer>
            {(this.state.is_hse || this.state.is_admin) && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  let payload = {
                    status: "H",
                    visit_time: this.state.input.visit_time,
                  };
                  if (this.state.is_hse) {
                    payload = {
                      status: "A",
                    };
                  }
                  if (this.state.is_admin) {
                    payload = {
                      status: "P",
                    };
                  }
                  this.updateRow(payload);
                }}
              >
                {is_updating ? "Saving.." : i18n.t("submit")}
              </button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          contentClassName="bg-primary"
          show={this.state.show_otp}
          onHide={() => this.setState({ show_otp: false })}
        >
          <Modal.Body>
            {this.state.row_otp && (
              <h1 className="text-white text-center">
                {i18n.t("verification_code")} : <b>{this.state.row_otp}</b>
              </h1>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-outline-info"
              onClick={() => this.setState({ show_otp: false })}
            >
              {i18n.t("close")}
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
