import React from "react";
import Countdown from "react-countdown";
import { CarouselAuctionDetails } from "../carousel/CarouselAuctionDetails";
import i18n from "../../i18n";
import {
  getAuction,
  addViewCount,
  toggleWishlist,
  toggleLike,
  toggleCalendar,
} from "../../models/auctions";
import { AuctionsDetailTabs } from "./helpers/auctionDetailTabs";
import { BiddingComp } from "../helpers/biddingComp";
import { AuctionShare } from "./helpers/auctionShare";
import MapPicker from "react-google-map-picker";
import { SkeletonAuctionDetails } from "../helpers/skeletons/skeletonAuctionDetails";
import { AuctionDocuments } from "./helpers/auctionDocuments";
import labelarabic from "../../images/labelarabic.png";
import LabelEng from "../../images/LabelEng.png";
import { formatCurrency } from "../../state/utils";
import { Helmet } from "react-helmet";
import { baseUrl } from "../../constants/global";
import { HomeBanner } from "./homeBanner";
//import { Categories } from "./categories";
import swal from "sweetalert";

let is_logged = localStorage.getItem("is_logged_in") ? true : false;
let current_lng = localStorage.getItem("I18N_LANGUAGE");
const FinishBid = () => (
  <div className="p-2 text-center text-success" style={{ fontSize: "1.1rem" }}>
    {i18n.t("auction_started")}
  </div>
);
const prependZeroIfNeeded = (number) => {
  return number.toString().length === 1 ? `0${number}` : number;
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <div style={{ position: "relative" }}>
        <div className="clock-div d-flex p-1 py-2 gap-2 justify-content-center">
          <div>{i18n.t("starts_after")}</div>
          <div className="clock-item">
            {prependZeroIfNeeded(days)} :
            <span className="border-span days">{i18n.t("lbl_day")}</span>
          </div>
          <div className="clock-item">
            {prependZeroIfNeeded(hours)} :
            <span className="border-span hours">{i18n.t("lbl_hour")}</span>
          </div>
          <div className="clock-item">
            {prependZeroIfNeeded(minutes)} :
            <span className="border-span minutes">{i18n.t("lbl_minute")}</span>
          </div>
          <div className="clock-item">
            {prependZeroIfNeeded(seconds)}
            <span className="border-span seconds">{i18n.t("lbl_second")}</span>
          </div>
        </div>
      </div>
    );
  }
};
export class AuctionDetails extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      wishlist: false,
      like: false,
      calendar: false,
      auctionId: auctionId,
      auction: [],
      toggleArrow: false,
      bid_increment: 0,
      current_bid_amount: 0,
      user_bid_amount: 0,
      left_button_disable: true,
      is_bid_sending: false,
      bid_place_message: "",
      enquiries: [{ label: "", value: "" }],
      is_loading: false,
      bidTimeStopped: false,
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };

    this.updateMetaTags = this.updateMetaTags.bind(this);
    this.addWishList = this.addWishList.bind(this);
    this.addLike = this.addLike.bind(this);
    this.addCalendar = this.addCalendar.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.addViewCount();
    this.loadAuction();
  }

  updateMetaTags(auction) {
    let { id, title, images, description } = auction;
    console.log("Count : " + id);
    let first_image = images && images.length > 0 ? images[0].image : false;
    let url = baseUrl + "auction-details/" + id;

    const updatedMetaTags = [
      { property: "og:title", content: title },
      { property: "og:type", content: "Auctions" },
      { property: "og:url", content: url },
      { property: "og:site_name", content: "Mzadcom" },
      { property: "og:description", content: description },
      { property: "og:image", content: first_image },
      { name: "twitter:card", content: "app" },
      { name: "twitter:title", content: "Mzadcom Auctions" },
      { name: "twitter:text:title", content: "Mzadcom Auctions" },
      { name: "twitter:image", content: first_image },
      { name: "twitter:description", content: description },
    ];

    return (
      <Helmet>
        <title>{title}</title>
        {updatedMetaTags.map((tag, index) => (
          <meta key={index} {...tag} />
        ))}
      </Helmet>
    );
  }

  async addViewCount() {
    await addViewCount({ auction_id: this.state.auctionId });
  }

  async loadAuction() {
    this.setState({ is_loading: true });
    let id = this.state.auctionId;
    const response = await getAuction(id);
    if (response) {
      let auction = response.data;
      let enquiry = auction.enquiry;
      auction.selected_mask = auction.mask;
      this.setState({
        auction: auction,
        current_bid_amount: auction.start_amount,
        enquiries: enquiry,
        is_loading: false,
      });
      this.setState({ like: auction.auction_liked });
      this.setState({ wishlist: auction.auction_wishlisted });
      this.setState({ calendar: auction.calendar });
    }
  }

  async addWishList(wishlist) {
    let { auction } = this.state;
    if (is_logged) {
      const response = await toggleWishlist({
        auction_id: auction.id,
        wishlist,
      });
      if (response) {
        this.setState({ wishlist: wishlist });
      }
    }
  }

  async addLike(like) {
    let { auction } = this.state;
    if (is_logged) {
      const response = await toggleLike({
        auction_id: auction.id,
        like,
      });
      if (response) {
        this.setState({ like: like });
      }
    }
  }

  async addCalendar(calendar) {
    let { auction } = this.state;
    if (is_logged) {
      const response = await toggleCalendar({
        auction_id: auction.id,
        calendar,
      });
      if (response) {
        this.setState({ calendar: calendar });
        if (calendar) {
          swal("Success", i18n.t("added_to_calendar"), "success");
        } else {
          swal("Success", i18n.t("removed_from_calendar"), "success");
        }
      }
    }
  }

  createDate(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let auction = this.state.auction;
    let email_cont = `mailto:your@friendsmail.com?subject=${auction.title}&body=${auction.description}\r\n${window.location.href}`;
    let action_name = current_lng === "ar" ? auction.title_ar : auction.title;
    //let start_date = auction.start_date_formatted;
    //let end_date = auction.end_date_formatted;
    let auction_images = auction.images;

    let statusAr = auction.status_label;
    let disp_status = "";
    let statusClass = "";
    let statusClasses = {
      E: "",
      F: "status-feat",
      A: "status-act",
      U: "status-upc",
    };
    if (statusAr && statusAr["status"]) {
      statusClass = statusClasses[statusAr["status"]];
      disp_status = current_lng === "ar" ? statusAr["ar"] : statusAr["en"];
    }
    let startDateAr = auction.start_date_ar;
    let endDateAr = auction.end_date_ar;

    let now = this.state.now;
    let endTime = new Date(this.createDate(auction.start_date)).getTime();

    let end_time = endDateAr && endDateAr["time"] ? endDateAr["time"] : "";
    end_time = end_time.replace("PM", i18n.t("PM"));
    end_time = end_time.replace("AM", i18n.t("AM"));
    let start_time =
      startDateAr && startDateAr["time"] ? startDateAr["time"] : "";
    start_time = start_time.replace("PM", i18n.t("PM"));
    start_time = start_time.replace("AM", i18n.t("AM"));
    let guarantee_amount = Number(auction.guarantee_amount);

    let updatedMetaTags = "";
    if (auction && auction.id) {
      updatedMetaTags = this.updateMetaTags(auction);
    }
    let addCalendarClass =
      "p-2 text_div custom_btn-color3 rounded-5 btn-block border border-1";
    if (this.state.calendar) {
      addCalendarClass =
        "p-2 text_div btn btn-danger rounded-5 btn-block border border-1";
    }
    return (
      <React.Fragment>
        <div className="page-container mb-5">
          <HomeBanner callbackForSupport={this.props.callbackForSupport} />
          {/* <Categories /> */}

          {updatedMetaTags}
          {this.state.is_loading && (
            <div className="cover-image sptb-1 bg-white text-center mt-8 mb-8 mx-5">
              <SkeletonAuctionDetails />
            </div>
          )}

          {!this.state.is_loading && (
            <div className="mt-5">
              <section className="sptb auction-details-section">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12">
                      <div className="rounded-pill border d-flex custom_btn-color mb-2">
                        <div className="p-2">
                          <div className="rounded-pill px-2 w-100 border custom_btn-color2">
                            <span className="custom_text">
                              {i18n.t("MZAD")}
                              {auction.id}
                            </span>
                          </div>
                        </div>
                        <div className="p-2 text_div">
                          <span className="custom_text2 is-size-12">
                            {action_name}
                          </span>
                        </div>
                      </div>
                      <div className="card overflow-hidden image-holder">
                        {auction.status_dis === "A" && (
                          <div className="rounded-pill p-1 px-4 active_cus success">
                            <span>{i18n.t("active")}</span>
                          </div>
                        )}
                        {auction.status_dis === "E" && (
                          <div className="rounded-pill p-1 px-4 active_cus danger">
                            <span>{i18n.t("expired")}</span>
                          </div>
                        )}
                        {auction.status_dis === "U" && (
                          <div className="rounded-pill p-1 px-4 active_cus info">
                            <span>{i18n.t("upcoming")}</span>
                          </div>
                        )}
                        {auction.status_dis === "F" && (
                          <div className="rounded-pill p-1 px-4 active_cus warning">
                            <span>{i18n.t("featured")}</span>
                          </div>
                        )}
                        {auction_images && auction_images.length === 0 ? (
                          <div className="border text-center">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/placeholder.jpg"
                              }
                              alt=""
                              className="m-auto w-100"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {auction_images && auction_images.length > 0 && (
                          <CarouselAuctionDetails images={auction_images} />
                        )}
                        <div
                          className="card-body"
                          style={{ padding: 0, position: "relative" }}
                        >
                          <div className=" Bid_Count_div d-flex gap-2">
                            <div
                              className="rounded-pill p-1 cus_felx border text-dark"
                              style={{ backgroundColor: "#A4D4E2" }}
                            >
                              <span
                                style={{
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }}
                                className="bid-count px-4"
                              >
                                {i18n.t("bid_counr")}:{auction.bid_count}
                              </span>
                            </div>
                            {auction.vat && (
                              <div
                                className="rounded-pill p-1 cus_felx border"
                                style={{ backgroundColor: "#A4D4E2" }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                  }}
                                  className="vat px-4"
                                >
                                  {i18n.t("vat")}:{auction.vat}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Show only on mobile */}
                        <div className="row show_mobile">
                          <div className="d-flex mb-0">
                            <div
                              className="p-2 bd-highlight"
                              onClick={(event) => {
                                this.addWishList(!this.state.wishlist);
                              }}
                            >
                              <img
                                height="15"
                                width="15"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/ph_heart.png"
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className="p-2 bd-highlight"
                              onClick={(event) => {
                                this.addLike(!this.state.like);
                              }}
                            >
                              <img
                                height="15"
                                width="15"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/iconamoon_like-light.png"
                                }
                                alt=""
                              />
                            </div>
                            <div className="ms-auto p-2 bd-highlight">
                              <img
                                height="15"
                                width="15"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/ph_eye.png"
                                }
                                alt=""
                              />
                              <span>{auction.total_views}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row px-1 py-1 pb-1">
                          <div className="col-lg-4 col-md-6 mb-1">
                            <div className="bg-light rounded-4 p-3 text-center card-background">
                              <div>
                                <span className="custom_text3">
                                  {startDateAr && startDateAr["day"]
                                    ? i18n.t(startDateAr["day"])
                                    : ""}
                                </span>
                                <br />
                                <span className="custom_text4">
                                  {i18n.t("start_date")}
                                </span>
                                <br />
                                <span className="custom_text5">
                                  {startDateAr && startDateAr["date"]
                                    ? startDateAr["date"]
                                    : ""}
                                </span>
                                <br />
                                <span className="custom_text6">
                                  ({start_time})
                                </span>
                              </div>
                              <div className="bg-white rounded-3 border p-1 mt-2 custom_bgcolor d-flex justify-content-center align-items-center">
                                <i className="fa fa-calendar text-danger" />
                                <span className="mx-1">
                                  {auction.days_remaining}
                                </span>
                                <span className="custom_text4 mx-1">
                                  {i18n.t("days_of_auction")}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 mb-1">
                            <div className="bg-light rounded-4 p-1 text-center card-background">
                              {auction.is_bid_time_ends && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="rounded-pill border text-center">
                                      <div className="p-3 text-center text-danger">
                                        {i18n.t("bid_time_ends")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="rounded-pill border text-center">
                                    <span className="custom_text7_green">
                                      {!auction.is_bid_time_ends &&
                                        auction.end_date && (
                                          <Countdown
                                            date={endTime}
                                            renderer={renderer}
                                            onComplete={() => {
                                              this.setState({
                                                bidTimeStopped: true,
                                              });
                                            }}
                                            now={now}
                                          />
                                        )}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className=" bg-white rounded-4 card-background p-2 mt-3">
                                    <div>
                                      <p className="custom_text6 pb-0 mb-1">
                                        {i18n.t("start_bid_amount")}:{" "}
                                        {formatCurrency(auction.start_amount)}{" "}
                                        {i18n.t("omr")}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="custom_text6 pb-0 mb-1">
                                        {i18n.t("gurantee_amount")}:{" "}
                                        {formatCurrency(guarantee_amount)}{" "}
                                        {i18n.t("omr")}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="custom_text6 pb-0 mb-1">
                                        {i18n.t("visit_amount")}:{" "}
                                        {formatCurrency(auction.visit_amount)}{" "}
                                        {i18n.t("omr")}
                                      </p>
                                    </div>
                                    <div>
                                      <p className="custom_text6 pb-0 text-danger">
                                        {i18n.t("current_amount")}:{" "}
                                        {formatCurrency(auction.current_amount)}{" "}
                                        {i18n.t("omr")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-12 mb-1">
                            <div className="bg-light rounded-4 p-1 text-center card-background">
                              <div className="row">
                                <div className="col-md-12">
                                  <div>
                                    <div className="d-flex bd-highlight mb-0 justify-content-between d-mob-none">
                                      <div className="d-flex gap-1">
                                        <div
                                          className="sc-highlight"
                                          onClick={(event) => {
                                            this.addWishList(
                                              !this.state.wishlist
                                            );
                                          }}
                                        >
                                          <img
                                            height="15"
                                            width="15"
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/ph_heart.png"
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div
                                          className="sc-highlight"
                                          onClick={(event) => {
                                            this.addLike(!this.state.like);
                                          }}
                                        >
                                          <img
                                            height="15"
                                            width="15"
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/iconamoon_like-light.png"
                                            }
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className="sc-highlight gap-1"
                                        style={{ width: "50px" }}
                                      >
                                        <img
                                          height="15"
                                          width="15"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/ph_eye.png"
                                          }
                                          alt=""
                                        />
                                        <span>{auction.total_views}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-1">
                                <div className="col-md-12">
                                  <div className="bg-white rounded-4 border p-2 card-background">
                                    <div className="row">
                                      <div className="col gap-2 d-flex justify-content-center">
                                        {/* <div className="bg-white rounded-3 border">
                                          <div className="bg-white rounded-3 border custom_bgcolor align-self-start text-center p-1 mb-1">
                                            <img
                                              width="20"
                                              height="20"
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/uiw_time.png"
                                              }
                                              alt=""
                                            />
                                            <span className="custom_text8 ms-1">
                                              {i18n.t("time")}
                                            </span>
                                          </div>
                                          <div className="p-1">
                                            <span className="custom_text6">
                                              {end_time}
                                            </span>
                                          </div>
                                        </div> */}
                                        <div className="bg-white rounded-3 border">
                                          <div className="bg-white rounded-3 border custom_bgcolor align-self-start text-center p-1 mb-1 d-flex justify-content-center align-items-center">
                                            <i className="mdi mdi-calendar-clock text-danger" />
                                            <span className="custom_text8 mx-1">
                                              {i18n.t("end_date")}
                                              {" & "}
                                              {i18n.t("time")}
                                            </span>
                                          </div>
                                          <div className="p-1">
                                            <span className="custom_text6">
                                              {endDateAr && endDateAr["date"]
                                                ? endDateAr["date"]
                                                : ""}{" "}
                                              {end_time}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className=" rounded rounded-pill border text-center custom-bg-color2 mt-2">
                                      <span className="custom_text9">
                                        {i18n.t("final_approval_lbl")}
                                      </span>
                                    </div>
                                    <div className="mt-2 text-center link_text">
                                      {/* <a href="#"><span className="link_text">Additional
                                                                    Information</span></a> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <a
                                target="_new"
                                href={auction.file_additional_information}
                                style={{
                                  color: "#ff064e",
                                  fontSize: "0.7rem",
                                  textDecoration: "underline",
                                }}
                              >
                                {i18n.t("additional_information")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <AuctionDocuments auction={auction} lng={current_lng} />
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-12">
                      <div className="rounded-pill border bg-white text-center">
                        <button
                          class={addCalendarClass}
                          onClick={(event) => {
                            this.addCalendar(!this.state.calendar);
                          }}
                          type="button"
                        >
                          <i className="mdi mdi-calendar-plus text-white" />
                          <span className="custom_text_white ms-2">
                            {this.state.calendar
                              ? i18n.t("remove_from_calendar")
                              : i18n.t("add_to_calendar")}
                          </span>
                        </button>
                      </div>

                      {this.state.auctionId && auction && (
                        <BiddingComp auction={auction} lng={current_lng} />
                      )}

                      <div className="card mt-3 border rounded-5">
                        <div
                          className="card-body p-0 border rounded-5"
                          style={{ minHeight: "100px" }}
                        >
                          <div className="" style={{ overflow: "hidden" }}>
                            <div className="map-header-layer" id="map2">
                              {auction.latitude && auction.longitude && (
                                <MapPicker
                                  defaultLocation={{
                                    lat: parseFloat(auction.latitude),
                                    lng: parseFloat(auction.longitude),
                                  }}
                                  zoom={15}
                                  mapTypeId="satellite"
                                  style={{ height: "250px" }}
                                  apiKey="AIzaSyDfYuPapVCAb5QOJZKQ36oB-vE9hRaVSO8"
                                  className="border rounded-5"
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <a
                          href={
                            "https://www.google.com/maps?q=" +
                            auction.latitude +
                            ", " +
                            auction.longitude
                          }
                          target="_new"
                          className="btn custom_btn-color3 rounded-pill position-absolute bottom-0 mb-4 btn-responsive"
                          style={{ left: "30%" }}
                        >
                          {i18n.t("open_google_map")}
                        </a>
                      </div>

                      <div className="card border rounded-pill">
                        <div className="p-1">
                          <AuctionShare auction={auction} />
                        </div>
                      </div>

                      <div>
                        <div className="mb-2">
                          <button
                            className="btn btn-block btn-warning border rounded-5 py-2"
                            type="button"
                            onClick={(event) => {
                              this.addWishList(!this.state.wishlist);
                            }}
                          >
                            <i
                              className={
                                this.state.wishlist
                                  ? "fa fa-heart"
                                  : "fa fa-heart-o"
                              }
                            />
                            &nbsp; {i18n.t("add_to_wishlist")}
                          </button>
                        </div>
                        <div>
                          <a
                            className="btn btn-block btn-warning border rounded-5 py-2"
                            href={email_cont}
                          >
                            <i className="fa fa-envelope-o" />
                            &nbsp; {i18n.t("email_to_a_friend")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <AuctionsDetailTabs auction={auction} lng={current_lng} />
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
