import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../i18n";
import { getLoggedUser } from "../../state/user";
import { getNotificationCounts } from "../../models/payments";
import { SideBarMob } from "./sidebar-mob";
import { getUserInfo } from "../../models/users";
import { generateMzadcomID } from "../../state/utils";

const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const allowed_roles = ["CE", "GM"];

function logout() {
  localStorage.clear();
  window.location.href = "/";
}

export class SideBar extends React.Component {
  constructor() {
    super();
    this.state = {
      dashboard_menu_expand: true,
      report_menu_expand: true,
      wallet_amount: 0,
    };
    this.notificationCheck = this.notificationCheck.bind(this);
  }

  componentDidMount() {
    if (user && allowed_roles.includes(user.role) ? true : false) {
      this.notificationCheck();
      setInterval(() => {
        this.notificationCheck();
      }, 60000);
    }
  }

  async checkUserWallet() {
    this.setState({ is_loading: true });
    const response = await getUserInfo(user.id);
    if (response && response.success) {
      let data = response.data;
      this.setState({ is_loading: false });
      this.setState({ wallet_amount: data.wallet_amount });
    } else {
      this.setState({ is_loading: false });
    }
  }

  async notificationCheck() {
    let response = await getNotificationCounts();
    let data = response.data;
    this.setState({ count: data.count });
  }

  render() {
    let { count, wallet_amount } = this.state;
    let lang = localStorage.getItem("I18N_LANGUAGE");
    return (
      <React.Fragment>
        {is_logged && (
          <div>
            <SideBarMob wallet_amount={wallet_amount} />
            <div class="row mb-5 d-mob-none">
              <div class="col-md-12">
                <div class="dashboard-bg rounded-4 text-center text-white fwb fs32 py-4 mb-3">
                  <img
                    class="px-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/dashboard-icon.png"
                    }
                    alt="Dashboard Icon"
                  />
                  <span class="dashboard-text">{i18n.t("dashboard")}</span>
                </div>
              </div>

              <div className="card" style={{ margin: "0", padding: "0" }}>
                <div class="p-2 user-brd rounded-4 mt-5 mx-3 mb-5">
                  <div class="d-flex">
                    <div class="px-2">
                      {user.avatar && (
                        <img
                          src={user.avatar}
                          alt="user"
                          className="rounded rounded-lg"
                          referrerPolicy="no-referrer"
                          width={35}
                        />
                      )}
                      {!user.avatar && (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/admin-user-icon.png"
                          }
                          alt="Admin User Icon"
                        />
                      )}
                    </div>
                    <div>
                      <div class="fs24 text-black lh28">{user.name}</div>
                      <div class="text-gray fs10">{user.email}</div>
                    </div>
                  </div>
                  <hr class="px-3" />
                  <div class="fs14 text-black">
                    <span class="text-gray"> {i18n.t("mobile")}: </span>
                    {user.mobile}
                    <br />
                    <span class="text-gray"> {i18n.t("mzadcom_id")}: </span>
                    {generateMzadcomID(user.id, user.role)}
                    <br />
                    <span class="text-gray">{i18n.t("email")}: </span>
                    {user.email}
                  </div>
                </div>

                <div className="item1-links  mb-0">
                  {["U", "CE", "GM"].includes(user.role) && (
                    <NavLink
                      activeClassName="active"
                      to="/dashboard"
                      className="d-flex border-bottom"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/dashboard-sub1.png"
                        }
                        alt=""
                        height="20"
                        width="20"
                        className="mx-3"
                      />
                      {i18n.t("dashboard")}
                    </NavLink>
                  )}
                  {!["U", "CE", "GM"].includes(user.role) && (
                    <div>
                      <a
                        className="d-flex border-bottom"
                        href={true}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.setState({
                            dashboard_menu_expand:
                              !this.state.dashboard_menu_expand,
                          });
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/dashboard-left-menu-icon.png"
                          }
                          alt=""
                          height="20"
                          width="20"
                          className="mx-3"
                        />
                        {i18n.t("dashboard")}
                      </a>
                      <div
                        className={
                          this.state.dashboard_menu_expand
                            ? "m-2"
                            : "d-none m-3"
                        }
                      >
                        <NavLink
                          activeClassName="active"
                          to="/dashboard"
                          className="d-flex border-bottom"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/dashboard-sub1.png"
                            }
                            alt=""
                            height="20"
                            width="20"
                            className="mx-3"
                          />
                          {i18n.t("live_dashboard")}
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/dashboard-auc-eval"
                          className="d-flex border-bottom"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/dashboard-sub2.png"
                            }
                            alt=""
                            height="20"
                            width="20"
                            className="mx-3"
                          />
                          {i18n.t("auction_evaluation")}
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/dashboard-user-analysis"
                          className="d-flex border-bottom"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/dashboard-sub3.png"
                            }
                            alt=""
                            height="20"
                            width="20"
                            className="mx-3"
                          />
                          {i18n.t("user_analysis")}
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/dashboard-sales-eval"
                          className="d-flex border-bottom"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/dashboard-sub4.png"
                            }
                            alt=""
                            height="20"
                            width="20"
                            className="mx-3"
                          />
                          {i18n.t("sales_evaluation")}
                        </NavLink>
                        {/* <NavLink
                          activeClassName="active"
                          to="/dashboard-auc-materials"
                          className="d-flex border-bottom"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/dashboard-sub5.png"
                            }
                            alt=""
                            height="20"
                            width="20"
                            className="mx-3"
                          />
                          {i18n.t("auction_materials")}
                        </NavLink> */}
                      </div>
                    </div>
                  )}

                  <NavLink
                    activeClassName="active"
                    to="/profile"
                    className="d-flex border-bottom"
                  >
                    <span className="icon1 mx-3">
                      <i className="icon icon-user"></i>
                    </span>{" "}
                    {i18n.t("edit_profile")}
                  </NavLink>

                  {(user.role === "A" ||
                    user.role === "PM" ||
                    user.role === "GM" ||
                    user.role === "CE" ||
                    user.role === "M") && (
                    <div>
                      <NavLink
                        to="/live_activity"
                        activeClassName="active"
                        className="d-flex  border-bottom"
                        target="_blank"
                      >
                        <span className="icon1 mx-3">
                          <i className="fa fa-desktop"></i>
                        </span>{" "}
                        {i18n.t("live_activity")}
                      </NavLink>

                      <NavLink
                        to="/users"
                        activeClassName="active"
                        className="d-flex  border-bottom"
                      >
                        <span className="icon1 mx-3">
                          <i className="fa fa-users"></i>
                        </span>{" "}
                        {i18n.t("users")}
                      </NavLink>

                      <NavLink
                        to="/clients"
                        activeClassName="active"
                        className="d-flex  border-bottom"
                      >
                        <span className="icon1 mx-3">
                          <i className="mdi mdi-account-multiple"></i>
                        </span>{" "}
                        {i18n.t("clients")}
                      </NavLink>
                    </div>
                  )}

                  {["A", "F", "CE", "GM"].includes(user.role) && (
                    <div>
                      <NavLink
                        to="/users-wallet"
                        activeClassName="active"
                        className="d-flex  border-bottom"
                      >
                        <span className="icon1 mx-3">
                          <i className="fa fa-money"></i>
                        </span>
                        <span className="text-justify">
                          {i18n.t("mzadcom")} - {i18n.t("wallet")}
                        </span>
                      </NavLink>
                      <NavLink
                        to="/withdraw-requests"
                        activeClassName="active"
                        className="d-flex  border-bottom"
                      >
                        <span className="icon1 mx-3">
                          <i className="fa fa-undo"></i>
                        </span>
                        <span className="text-justify">
                          {i18n.t("withdraw_requests")}
                        </span>
                      </NavLink>
                    </div>
                  )}

                  {user.role === "A" && (
                    <NavLink
                      to="/groups"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="fa fa-object-group"></i>
                      </span>{" "}
                      {i18n.t("groups")}
                    </NavLink>
                  )}
                  {user.role === "A" && (
                    <NavLink
                      to="/masks"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="icon icon-layers"></i>
                      </span>{" "}
                      {i18n.t("masks")}
                    </NavLink>
                  )}
                  {user.role === "A" && (
                    <NavLink
                      to="/categories"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="fa fa-list-alt"></i>
                      </span>{" "}
                      {i18n.t("categories")}
                    </NavLink>
                  )}
                  {user.role === "A" && (
                    <NavLink
                      to="/organizations"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="fa fa-building"></i>
                      </span>{" "}
                      {i18n.t("organizations")}
                    </NavLink>
                  )}
                  {user.role === "A" && (
                    <NavLink
                      to="/banners"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="fa fa-image"></i>
                      </span>{" "}
                      {i18n.t("banners")}
                    </NavLink>
                  )}
                  {user.role === "A" && (
                    <NavLink
                      to="/blacklist"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="fa fa-ban"></i>
                      </span>{" "}
                      {i18n.t("blacklist")}
                    </NavLink>
                  )}
                  {user.role === "A" && (
                    <NavLink
                      to="/create_auction"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="icon icon-plus"></i>
                      </span>{" "}
                      {i18n.t("create_auction")}
                    </NavLink>
                  )}
                  {(user.role === "A" ||
                    user.role === "C" ||
                    user.role === "PM" ||
                    user.role === "GM" ||
                    user.role === "CE" ||
                    user.role === "M") && (
                    <NavLink
                      to="/auctions_list"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="icon icon-diamond"></i>
                      </span>{" "}
                      {i18n.t("my_auctions")}
                    </NavLink>
                  )}
                  {(user.role === "A" || user.role === "PM") && (
                    <NavLink
                      to="/auction_approval"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <img
                        class="px-2"
                        src={
                          process.env.PUBLIC_URL + "/assets/images/image 25.png"
                        }
                        alt=""
                        width="20"
                        height="20"
                        className="mx-3"
                      />
                      {i18n.t("auction_approval")}
                    </NavLink>
                  )}
                  {user.role === "A" && (
                    <NavLink
                      to="/approved_auctions"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="mx-3">
                        <i className="mdi mdi-checkbox-marked-circle-outline"></i>
                      </span>
                      {i18n.t("approved_auctions")}
                    </NavLink>
                  )}
                  {user.role === "U" && (
                    <NavLink
                      activeClassName="active"
                      to="/my_auctions"
                      className="d-flex  border-bottom"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/solar_key-outline.png"
                        }
                        alt=""
                        height="20"
                        width="20"
                        className="mx-3"
                      />
                      {i18n.t("my_bids")}
                    </NavLink>
                  )}
                  {user.role === "U" && (
                    <NavLink
                      activeClassName="active"
                      to="/enrolled_auctions"
                      className="d-flex  border-bottom"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/auctions-left-menu-icon.png"
                        }
                        alt=""
                        height="20"
                        width="20"
                        className="mx-3"
                      />
                      {i18n.t("enrolled_auctions")}
                    </NavLink>
                  )}
                  {user.role === "U" && (
                    <NavLink
                      activeClassName="active"
                      to="/winning_bids"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="icon icon-trophy"></i>
                      </span>{" "}
                      {i18n.t("winning_bids")}
                    </NavLink>
                  )}

                  <NavLink
                    activeClassName="active"
                    to="/site_visit"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 mx-3">
                      <i className="icon icon-credit-card"></i>
                    </span>{" "}
                    {i18n.t("site_visit")}
                  </NavLink>

                  {user.role === "U" && (
                    <NavLink
                      activeClassName="active"
                      to="/wallet"
                      className="d-flex  border-bottom"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/mzadcom-wallet-left-menu-icon.png"
                        }
                        alt=""
                        height="20"
                        width="20"
                        className="mx-3"
                      />
                      {i18n.t("my_wallet")}
                    </NavLink>
                  )}

                  {(user.role === "A" ||
                    user.role === "C" ||
                    user.role === "M") && (
                    <NavLink
                      activeClassName="active"
                      to="/payments"
                      className="d-flex border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="icon icon-credit-card"></i>
                      </span>{" "}
                      {i18n.t("payment")}
                    </NavLink>
                  )}

                  {(user.role === "A" || user.role === "PM") && (
                    <NavLink
                      activeClassName="active"
                      to="/client-payments"
                      className="d-flex border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="mdi mdi-bank"></i>
                      </span>{" "}
                      {i18n.t("client_payment")}
                    </NavLink>
                  )}

                  {(user.role === "A" ||
                    user.role === "PM" ||
                    user.role === "GM" ||
                    user.role === "CE") && (
                    <NavLink
                      activeClassName="active"
                      to="/refund"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="fa fa-money"></i>
                      </span>{" "}
                      {i18n.t("refund")} &nbsp;
                      {allowed_roles.includes(user.role) && (
                        <span className="badge rounded-circle badge-notification bg-danger">
                          {count}
                        </span>
                      )}
                    </NavLink>
                  )}

                  <NavLink
                    activeClassName="active"
                    to="/auction_tracker"
                    className="d-flex border-bottom"
                  >
                    <span className="icon1 mx-3">
                      <i className="mdi mdi-history"></i>
                    </span>{" "}
                    {i18n.t("track_auctions")}
                  </NavLink>

                  <NavLink
                    activeClassName="active"
                    to="/auction_liked"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 mx-3">
                      <i className="icon icon-like"></i>
                    </span>{" "}
                    {i18n.t("liked_auctions")}
                  </NavLink>
                  <NavLink
                    activeClassName="active"
                    to="/auction_wishlisted"
                    className="d-flex  border-bottom"
                  >
                    <span className="icon1 mx-3">
                      <i className="icon icon-heart"></i>
                    </span>{" "}
                    {i18n.t("wishlisted_auctions")}
                  </NavLink>

                  {(user.role === "A" ||
                    user.role === "C" ||
                    user.role === "PM" ||
                    user.role === "GM" ||
                    user.role === "CE" ||
                    user.role === "F" ||
                    user.role === "M") && (
                    <div>
                      <NavLink
                        activeClassName="active"
                        to="/enrolled_users"
                        className="d-flex  border-bottom"
                      >
                        <span className="icon1 mx-3">
                          <i className="icon icon-credit-card"></i>
                        </span>{" "}
                        {i18n.t("enrolled_users")}
                      </NavLink>
                      <a
                        href={true}
                        className="d-flex  border-bottom"
                        onClick={() => {
                          this.setState({
                            report_menu_expand: !this.state.report_menu_expand,
                          });
                        }}
                      >
                        <span className="icon1 mx-3">
                          <i
                            className={
                              this.state.report_menu_expand
                                ? "fa fa-minus"
                                : "fa fa-plus"
                            }
                          ></i>
                        </span>
                        <span>{i18n.t("reports")}</span>
                        <span
                          className="icon1 mx-3"
                          style={{
                            position: "absolute",
                            right: lang === "en" ? 0 : "auto",
                            left: lang === "ar" ? 0 : "auto",
                          }}
                        >
                          {/* <i
                        className={
                          this.state.report_menu_expand
                            ? "fa fa-arrow-up"
                            : "fa fa-arrow-down"
                        }
                      ></i> */}
                        </span>
                      </a>
                      <div
                        className={
                          this.state.report_menu_expand ? "m-2" : "d-none m-3"
                        }
                      >
                        {/* {
                      this.state.report_menu_expand ? "m-3" : "d-none m-3"
                    } */}
                        <NavLink
                          activeClassName="active"
                          to="/auction_bid_report"
                          className="d-flex border-bottom text-danger"
                        >
                          <span className="icon1 mx-3">
                            <i className="fa fa-file-o"></i>
                          </span>{" "}
                          {i18n.t("auction_bid_report")}
                        </NavLink>
                        <NavLink
                          activeClassName="active"
                          to="/winners"
                          className="d-flex border-bottom text-danger"
                        >
                          <span className="icon1 mx-3">
                            <i className="fa fa-flag-o"></i>
                          </span>{" "}
                          {i18n.t("auction_winners")}
                        </NavLink>
                        {(user.role === "A" ||
                          user.role === "PM" ||
                          user.role === "GM" ||
                          user.role === "CE" ||
                          user.role === "M") && (
                          <div>
                            <NavLink
                              to="/sales_report"
                              activeClassName="active"
                              className="d-flex border-bottom text-danger"
                            >
                              <span className="icon1 mx-3">
                                <i className="fa fa-money"></i>
                              </span>{" "}
                              {i18n.t("sales_report")}
                            </NavLink>
                            <NavLink
                              to="/final_report"
                              activeClassName="active"
                              className="d-flex border-bottom text-danger"
                            >
                              <span className="icon1 mx-3">
                                <i className="fa fa-hourglass-end"></i>
                              </span>{" "}
                              {i18n.t("final_report")}
                            </NavLink>
                            <NavLink
                              to="/position_report"
                              activeClassName="active"
                              className="d-flex border-bottom text-danger"
                            >
                              <span className="icon1 mx-3">
                                <i className="fa fa-key"></i>
                              </span>{" "}
                              {i18n.t("position_report")}
                            </NavLink>
                          </div>
                        )}
                        <NavLink
                          to="/reports"
                          activeClassName="active"
                          className="d-flex border-bottom text-danger"
                        >
                          <span className="icon1 mx-3">
                            <i className="icon icon-chart"></i>
                          </span>{" "}
                          {i18n.t("reports")}
                        </NavLink>
                      </div>
                    </div>
                  )}

                  {(user.role === "A" ||
                    user.role === "PM" ||
                    user.role === "GM" ||
                    user.role === "CE" ||
                    user.role === "M") && (
                    <NavLink
                      to="/composer"
                      activeClassName="active"
                      className="d-flex  border-bottom"
                    >
                      <span className="icon1 mx-3">
                        <i className="fa fa-bell-o"></i>
                      </span>{" "}
                      {i18n.t("composer")}
                    </NavLink>
                  )}

                  <a
                    href={true}
                    onClick={logout}
                    style={{ cursor: "pointer" }}
                    className="d-flex"
                  >
                    <span className="icon1 mx-3">
                      <i className="icon icon-power"></i>
                    </span>{" "}
                    {i18n.t("logout")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
